
import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

import '../css/main.css';



// Go to Top
// if .c-go-top exists
if (document.querySelector('.c-go-top')) {
  const topButton = document.querySelector('.c-go-top');
  // On click or tap, scroll to the top of the page
  topButton.addEventListener('click', () => {
    gsap.to(window, { duration: 2, scrollTo: 0 });
  });
}


// Custom Cursor
// Check if it's a touch device
const isTouchDevice = 'ontouchstart' in window;
const createCursorFollower = () => {
  const $el = document.querySelector('.cursor-follower');
  // Each time the mouse coordinates are updated,
  // we need to pass the values to gsap in order
  // to animate the element
  window.addEventListener('mousemove', (e) => {
    const { target, x, y } = e;
    // Check if target is inside a link or button
    const isTargetLinkOrBtn = target?.closest('a') || target?.closest('button');
    // GSAP config
    gsap.to($el, {
      x: x - 9.7,
      y: y - 10,
      duration: 0.7,
      ease: 'power4', // More easing options here: https://gsap.com/docs/v3/Eases/
      opacity: isTargetLinkOrBtn ? 0.6 : 1,
      transform: `scale(${isTargetLinkOrBtn ? 3 : 1})`,
    });
  });
  // Hidding the cursor element when the mouse cursor
  // is moved out of the page
  document.addEventListener('mouseleave', () => {
    gsap.to($el, {
      duration: 0.7,
      opacity: 0,
    });
  });
};
// Only invoke the function if isn't a touch device
if (!isTouchDevice) {
  createCursorFollower();
}


// Show the Map only when hovering over ".c-map-button"
if (document.querySelector('.c-map-button')) {
  const mapButton = document.querySelector('.c-map-button');
  const map = document.querySelector('.l-map-wrapper');
  mapButton.addEventListener('mouseenter', () => {
    gsap.to(map, { duration: 0.5, opacity: 1, display: 'block' });
  }
  );
  mapButton.addEventListener('mouseleave', () => {
    gsap.to(map, { duration: 0.5, opacity: 0, display: 'none' });
  }
  );
}