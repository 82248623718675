import '../css/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';



CookieConsent.run({

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {}
    },
    
    guiOptions: {
        consentModal: {
            equalWeightButtons: false
        },
        preferencesModal: {
            equalWeightButtons: false
        }
    },

    language: {
        default: 'en',

        translations: {
            en: {
                consentModal: {
                    title: '',
                    description: 'By clicking “Accept All”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. View our <a href="/cookies-policy/">Cookies Policy</a> for more information.',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    showPreferencesBtn: 'Manage Individual preferences'
                },
                preferencesModal: {
                    title: 'Privacy Preference Center',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    savePreferencesBtn: 'Accept current selection',
                    closeIconLabel: 'Close modal',
                    sections: [
                        {
                            title: 'What are cookies used for?',
                            description: 'When you visit websites, they may store or retrieve data in your browser. This storage is often necessary for the basic functionality of the website. The storage may be used for marketing, analytics, and personalization of the site, such as storing your preferences. Privacy is important to us, so you have the option of disabling certain types of storage that may not be necessary for the basic functioning of the website. Blocking categories may impact your experience on the website.'
                        },
                        {
                            title: 'Essential',
                            description: 'These cookies are essential for the proper functioning of the website and cannot be disabled. For example, we use a cookie just to store in your device your cookie preferences.',
  
                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Performance and Analytics',
                            description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                            linkedCategory: 'analytics'
                        },
                        {
                            title: 'More information',
                            description: 'If you want, you can access our <a href="/cookies-policy/">complete Cookies Policy</a>'
                        }
                    ]
                }
            },
            es: {
                consentModal: {
                    title: '',
                    description: 'Al hacer clic en “Aceptar todas”, aceptas el almacenamiento de cookies en tu dispositivo para mejorar la navegación del sitio, analizar el uso del sitio y ayudar en nuestros esfuerzos de marketing. Consulta nuestra <a href="/es/cookies-policy/">Política de Cookies</a> para obtener más información.',
                    acceptAllBtn: 'Aceptar todas',
                    acceptNecessaryBtn: 'Rechazar todas',
                    showPreferencesBtn: 'Gestionar preferencias individuales'
                },
                preferencesModal: {
                    title: 'Centro de Preferencias de Privacidad',
                    acceptAllBtn: 'Aceptar todas',
                    acceptNecessaryBtn: 'Rechazar todas',
                    savePreferencesBtn: 'Aceptar selección actual',
                    closeIconLabel: 'Cerrar modal',
                    sections: [
                        {
                            title: '¿Para qué se utilizan las cookies?',
                            description: 'Cuando visitas sitios web, pueden almacenar o recuperar datos en tu navegador. Este almacenamiento es a menudo necesario para la funcionalidad básica del sitio web. El almacenamiento puede ser utilizado para marketing, análisis y personalización del sitio, como almacenar tus preferencias. La privacidad es importante para nosotros, por lo que tienes la opción de desactivar ciertos tipos de almacenamiento que pueden no ser necesarios para el funcionamiento básico del sitio web. Bloquear categorías puede afectar tu experiencia en el sitio web.'
                        },
                        {
                            title: 'Esenciales',
                            description: 'Estas cookies son esenciales para el correcto funcionamiento del sitio web y no se pueden desactivar. Por ejemplo, utilizamos una cookie solo para almacenar en tu dispositivo tus preferencias de cookies.',
                            //este campo generará un interruptor vinculado a la categoría 'necesario'
                            linkedCategory: 'necesario'
                        },
                        {
                            title: 'Rendimiento y Análisis',
                            description: 'Estas cookies recopilan información sobre cómo utilizas nuestro sitio web. Todos los datos son anónimos y no pueden ser utilizados para identificarte.',
                            linkedCategory: 'análisis'
                        },
                        {
                            title: 'Más información',
                            description: 'Si lo deseas, puedes acceder a nuestra <a href="/es/cookies-policy/">Política de Cookies completa</a>'
                        }
                    ]
                }
            }
        }
    }
  })
  